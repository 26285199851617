var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"view_data"},[_c('el-card',{staticClass:"viewDataCard"},[_c('div',{staticClass:"header"},[_c('el-button',{staticClass:"button",on:{"click":_vm.exportPlacement}},[_vm._v("导出数据")])],1),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.importDataList,"tooltip-effect":"dark","header-cell-style":{
          fontWeight: 'normal',
          height: '60px',
          color: '#666666',
          background: '#F6F6F6',
          fontSize: '16px',
        },"cell-style":{ width: '120px', height: '60px', }}},[_c('el-table-column',{attrs:{"align":"center","prop":"order_date","label":"订单日期"}}),_c('el-table-column',{attrs:{"align":"center","prop":"sell_amout","label":"销售额(元)"}}),_c('el-table-column',{attrs:{"align":"center","prop":"order_num","label":"订单数量"}}),_c('el-table-column',{attrs:{"align":"center","prop":"user_inc_num","label":"用户增长"}}),_c('el-table-column',{attrs:{"align":"center","prop":"average_order_price","label":"平均订单金额"}}),_c('el-table-column',{attrs:{"align":"center","prop":"conversion_rate","label":"订单转化率"},scopedSlots:_vm._u([{key:"default",fn:function(scoped){return [_vm._v(" "+_vm._s(scoped.row.conversion_rate +'%')+" ")]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"refund_rate","label":"退货率"},scopedSlots:_vm._u([{key:"default",fn:function(scoped){return [_vm._v(" "+_vm._s(scoped.row.refund_rate +'%')+" ")]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"consumer_satisfaction_rate","label":"客户满意度"}}),_c('el-table-column',{attrs:{"align":"center","prop":"advertising_investment","label":"广告投入"}}),_c('el-table-column',{attrs:{"align":"center","prop":"purchasing_cost","label":"采购成本"}}),_c('el-table-column',{attrs:{"align":"center","prop":"roi","label":"ROI"}})],1),_c('div',{staticClass:"footet-pagination"},[_c('div',{staticClass:"total"},[_vm._v(" 共"+_vm._s(_vm.total)+"项数据 ")]),_c('el-pagination',{attrs:{"current-page":_vm.form.page,"page-size":10,"layout":"prev, pager, next, jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){return _vm.$set(_vm.form, "page", $event)},"update:current-page":function($event){return _vm.$set(_vm.form, "page", $event)}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }